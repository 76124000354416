<template>
  <div class="DomainTransferView">
    <section class="domain-transfer-view-header">
      <div class="o-overlay-5"></div>
      <div class="o-container">
        <div class="header-content">
          <h2 class="o-default-h2 u-strong">{{ $t('domainTransferView.title') }}</h2>
          <p class="o-default-body domain-transfer-description">{{ $t('domainTransferView.description') }}</p>
          <DomainTransferInput />
          <p class="o-default-body u-font-heading-bold domain-transfer-info">
            {{ $t('domainTransferView.info') }}
          </p>
        </div>
      </div>
    </section> <!-- /.domain-transfer-view-header -->
    <section v-if="domainChecked" class="o-container-gray">
      <DomainTransferVerification :domainChecked="domainChecked" />
    </section>
    <section class="domain-transfer-banner">
      <div class="o-container">
        <p class="o-default-body u-strong">{{ $t('domainTransferView.whyTransfer') }}</p>
        <div class="o-default-body u-strong domain-transfer-banner-anchor">
          <span @click="Helpers.scrollIntoView('DomainTransferPromo')" class="view-all-advantages">{{ $t('domainTransferView.viewAllAdvantages') }}</span>
        </div>
      </div>
    </section> <!-- /.domain-transfer-banner -->
    <section class="o-container-gray">
      <DomainTransferHowTo />
    </section>
    <section class="o-container-gray">
      <DomainTransferPromo />
    </section>

  </div> <!-- /.DomainTransferView -->
</template>

<script>
import { mapGetters } from 'vuex';
import DomainTransferInput from '@/components/domain-transfer/DomainTransferInput';
import DomainTransferVerification from '@/components/domain-transfer/DomainTransferVerification';
import DomainTransferHowTo from '@/components/domain-transfer/DomainTransferHowTo';
import DomainTransferPromo from '@/components/domain-transfer/DomainTransferPromo';

export default {
  name: 'DomainTransferView',
  components: {
    DomainTransferInput,
    DomainTransferVerification,
    DomainTransferHowTo,
    DomainTransferPromo
  },
  computed: {
    ...mapGetters([
      'domainChecked'
    ])
  }
};
</script>

<style lang="scss" scoped>
.DomainTransferView {

  .domain-transfer-view-header {
    background: url('../assets/images/page-header-transfer-domain.jpg') no-repeat center center;
    background-size: cover;
    height: 30rem;
    min-height: 30rem;
    position: relative;

    .o-container {
      height: 100%;
      position: relative;
    }

    .header-content {
      animation: fadein 2s;
      @keyframes fadein {
        from { opacity: 0; }
        to { opacity: 1; }
      }
      max-width: 120rem;
      padding: 0 0 1.6rem;
      position: absolute;
      text-align: left;
      top: 50%;
      transform: translate(0, -50%);
      width: calc(100% - .8rem);
      @include tablet { width: 80%; }
      @include desktop { width: 60%; }

      > h2 {
        color: $white;
        padding-bottom: 1.6rem;
      }

      > p {
        color: $white;
      }
    }

    .domain-transfer-description {
      margin-bottom: 1.2rem;
    }

    .domain-transfer-info {
      margin-top: 2.4rem;
    }
  }

  .domain-transfer-banner {
    @include disable-select;
    background-color: $palette-secondary-color-four;
    padding: 1.2rem 0;
    text-align: center;
    @include tablet {
      text-align: left;
    }

    .domain-transfer-banner-anchor {
      color: $palette-primary-color-three;
      display: inline-flex;
      margin: 0;
      @include tablet { margin: 0; }
    }

    p {
      color: $white;
      display: inline-flex;
      font-family: $font-family-body-thin;
      font-size: 1.6rem;
    }

    .view-all-advantages {
      cursor: pointer;
      font-family: $font-family-body-regular;
      font-size: 1.6rem;
      margin: 0 .8rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
