<template>
  <div class="DomainTransferInput">
    <input type="text"
           class="md-elevation-2 o-input-with-btn"
           :class="{ invalid: domainCheckedError }"
           name="transfer-domain"
           v-model.trim="domainToTransfer"
           :placeholder="placeholder"
           @keyup.enter="goToDomainTransferView" />
    <md-button class="md-raised md-primary transfer-domain-input-btn"
               @click="goToDomainTransferView">
      {{ $t('common.transfer') }}
    </md-button>
    <div v-if="domainCheckedError" class="transfer-domain-input-err u-red u-strong">{{ $t(`domainTransferInput.error.${domainCheckedError}`) }}</div>
  </div> <!-- /.DomainTransferInput -->
</template>

<script>
import Helpers from '@/common/helpers';
import { mapGetters } from 'vuex';
import {
  SET_DOMAIN_CHECKED,
  SET_DOMAIN_CHECKED_ERROR
} from '@/store/mutations.type';

export default {
  name: 'DomainTransferInput',
  data() {
    return {
      placeholder: this.$t('domainTransferInput.placeholder'),
      domainToTransfer: '',
      domainCheckErr: null
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'domainCheckedError'
    ])
  },
  methods: {
    containsSubstring(str, substr) {
      return str.indexOf(substr) !== -1;
    },
    beginsWith(str, prefix) {
      return str.indexOf(prefix) === 0;
    },
    isUrl(s) {
      const regexp = /^(((?!-))(xn--|_{1,1})?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
      return regexp.test(s);
    },
    goToDomainTransferView() {
      // Sanitize (to lowercase)
      this.domainToTransfer = this.domainToTransfer.toLowerCase();

      const substring = '.';

      if (!this.isAuthenticated) {
        this.$router.push({ name: 'sign-in' });
      } else {
        // Check if string is not empty
        if (this.domainToTransfer.length === 0) {
          this.$store.commit(SET_DOMAIN_CHECKED, null);
          this.domainCheckErr = 'invalid';
          this.$store.commit(SET_DOMAIN_CHECKED_ERROR, this.domainCheckErr);
          this.$store.commit(SET_DOMAIN_CHECKED, null);
          Helpers.scrollIntoView('transfer-domain-input-err');
          return;
        } else {
          this.$store.commit(SET_DOMAIN_CHECKED_ERROR, null);
        }

        // Check if the string begins with "."
        if (this.beginsWith(this.domainToTransfer, substring)) {
          this.domainCheckErr = 'invalid';
          this.$store.commit(SET_DOMAIN_CHECKED_ERROR, this.domainCheckErr);
          this.$store.commit(SET_DOMAIN_CHECKED, null);
          Helpers.scrollIntoView('transfer-domain-input-err');
          return;
        } else {
          this.$store.commit(SET_DOMAIN_CHECKED_ERROR, null);
        }

        // Check if the string contains http://, https:// or ftp://
        const protocols = ['http://', 'https://', 'ftp://', 'www.']

        protocols.forEach((item) => {
          if (this.containsSubstring(this.domainToTransfer, item)) {
            this.domainToTransfer = this.domainToTransfer.replace(item, '');
          }
        });

        // Check if the string contains "."
        const isDomainFormat = this.domainToTransfer.indexOf(substring) !== -1;

        if (this.domainToTransfer && !isDomainFormat) {
          this.domainToTransfer = `${this.domainToTransfer}.ro`;
          this.$store.commit(SET_DOMAIN_CHECKED, this.domainToTransfer);
          return;
        }

        // Check if the string is a valid URL
        const isValidUrl = this.isUrl(this.domainToTransfer);

        if (isValidUrl) {
          const tempTldCheck = this.domainToTransfer.split('.').pop();
          if (tempTldCheck !== 'ro') {
            this.domainCheckErr = 'tldNotSupported';
            this.$store.commit(SET_DOMAIN_CHECKED_ERROR, this.domainCheckErr);
            this.$store.commit(SET_DOMAIN_CHECKED, null);
            Helpers.scrollIntoView('transfer-domain-input-err');
          } else {
            this.$store.commit(SET_DOMAIN_CHECKED_ERROR, null);
            this.$store.commit(SET_DOMAIN_CHECKED, this.domainToTransfer);
          }
        } else {
          this.domainCheckErr = 'invalid';
          this.$store.commit(SET_DOMAIN_CHECKED_ERROR, this.domainCheckErr);
            this.$store.commit(SET_DOMAIN_CHECKED, null);
          Helpers.scrollIntoView('transfer-domain-input-err');
          return;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.DomainTransferInput {
  display: flex;
  flex-wrap: wrap;

  .transfer-domain-input-btn {
    font-size: $type-scale-size-7-fallback;
    font-size: $type-scale-size-7;
    width: auto;
    @include tablet { width: 16rem; }
    @include desktop { width: 20rem; }
  }

  .transfer-domain-input-err {
    flex-basis: 100%;
    margin: .8rem 0 0;
  }
}
</style>
