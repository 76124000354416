<template>
  <div class="DomainTransferPromo">
    <md-card class="card-steps">
      <md-card-header class="domain-transfer-card-header">
        <div class="md-title">{{ $t('domainTransferPromo.title') }}</div>
        <div class="md-layout">
          <section class="md-layout-item md-xlarge-size-60 md-large-size-60 md-medium-size-50 md-small-size-100 md-xsmall-size-100 u-text-justify">
            <p class="o-default-body">{{ $t('domainTransferPromo.description1') }}</p>
            <p class="o-default-body">{{ $t('domainTransferPromo.description2') }}</p>
            <p class="o-default-body text-highlight">{{ $t('domainTransferPromo.description3') }}</p>
          </section>
          <section class="md-layout-item md-xlarge-size-40 md-large-size-40 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
            <div class="img-frame"></div>
          </section>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter">
          <section v-for="(card) of orderedCards"
                   :key="card.key"
                   class="md-layout-item card-promo md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100">
            <div class="card-content-wrapper">
              <div class="card-header">
                <md-icon class="card-icon-small">{{ card.key | getCardIcon(cards) }}</md-icon>
                <h3 class="card-title text-highlight">{{ $t(`domainTransferPromo.cards.${card.key}.title`) }}</h3>
              </div>
              <div class="card-content">
                <p class="card-description">{{ $t(`domainTransferPromo.cards.${card.key}.description`) }}</p>
                <p class="card-description">{{ $t(`domainTransferPromo.cards.${card.key}.description2`) }}</p>
              </div>
              <div class="like-count" @click="toggleLikeDebounced(card.key)">
                <md-icon class="material-icons o-like-icon">{{ card.liked ? 'favorite' : 'favorite_border' }}</md-icon>
                <span class="o-like-icon-label">{{ card.likes }} votes</span>
              </div>
            </div>
          </section>
        </div>
        <div class="transfer-btn-container">
          <md-button class="md-raised md-primary transfer-btn" @click="Helpers.scrollIntoView('DomainTransferInput')">
            {{ $t('domainTransferPromo.transferBtn') }}
          </md-button>
        </div>
      </md-card-content>
    </md-card> <!-- /.card-registered-domains -->
  </div> <!-- /.DomainTransferPromo -->
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce, orderBy } from 'lodash';
import {
  FETCH_POPULAR_TRANSFER_BENEFITS,
  LIKE_POPULAR_TRANSFER_BENEFITS,
  REMOVE_LIKE_POPULAR_TRANSFER_BENEFITS
} from '@/store/actions.type';

export default {
  name: 'DomainTransferPromo',
  data() {
    return {
      cards: {
        price: 'local_offer',
        support: 'support_agent',
        domainManagement: 'settings',
        noRisk: 'local_police',
        freeTransfer: 'money_off',
        instant: 'bolt',
        noCommissions: 'credit_card_off',
        noCommitment: 'no_stroller',
        serviceAvailability: 'network_check'
      }
    }
  },
  computed: {
    ...mapGetters([
      'popularTransferBenefits'
    ]),
    orderedCards() {
      return orderBy(this.popularTransferBenefits, 'likes', 'desc')
    }
  },
  created() {
    this.fetchPopularTransferBenefits();
  },
  filters: {
    getCardIcon: function(cardKey, cards) {
      if (!cardKey) return '';
      return cards[cardKey];
    }
  },
  methods: {
    fetchPopularTransferBenefits() {
      this.$store.dispatch(FETCH_POPULAR_TRANSFER_BENEFITS);
    },
    toggleLikeDebounced: debounce(function (cardKey) {
      const elClickedIndex = this.orderedCards.findIndex(x => x.key === cardKey);

      if (this.orderedCards[elClickedIndex].liked === false) {
        this.$store.dispatch(LIKE_POPULAR_TRANSFER_BENEFITS, cardKey);
        this.$set(this.orderedCards[elClickedIndex], 'liked', true);
        this.orderedCards[elClickedIndex].likes += 1;
      } else {
        this.$store.dispatch(REMOVE_LIKE_POPULAR_TRANSFER_BENEFITS, cardKey);
        this.$set(this.orderedCards[elClickedIndex], 'liked', false);
        this.orderedCards[elClickedIndex].likes -= 1;
      }
    }, 400, { 'leading': true, 'trailing': false })
  }
};
</script>

<style lang="scss">
.DomainTransferPromo {

  .transfer-btn .md-button-content {
    font-family: $font-family-heading-medium;
  }
}
</style>

<style lang="scss" scoped>
.DomainTransferPromo {

  .domain-transfer-card-header {

    p {
      font-size: $type-scale-size-6-fallback;
      font-size: $type-scale-size-6;
      line-height: $type-scale-line-height-5-fallback;
      line-height: $type-scale-line-height-5;
      margin: 1.6rem 0;
    }
  }

  .text-highlight {
    color: $palette-primary-color-three;
    font-weight: 700;
  }

  .img-frame {
    background: url('../../assets/images/business-people-hi5.jpg') no-repeat center center;
    background-size: cover;
    border: .8rem solid $palette-primary-color-three;
    border-radius: .8rem;
    height: 20rem;

    @media (min-width: 961px) {
      margin: 2rem 0 2rem 4rem;
      width: calc(100% - 4rem);
    }

    @media (min-width: 1280px) {
      margin: 2rem 0;
      transform: translate(2.4rem, 0);
      width: 48rem;
    }
  }

  .domain-transfer-promo-img {
    margin: 1.6rem 0;
  }

  .card-promo {
    margin: 1.6rem 0;
  }

  .card-content-wrapper {
    border-bottom: .1rem solid $palette-secondary-color-three;
    height: 100%;
    padding-bottom: 6rem;
    position: relative;
  }

  .card-header {
    margin-bottom: 1.6rem;
  }

  .card-icon {
    color: $palette-primary-color-three;
    font-size: 80px !important;
    font-size: 8rem !important;
    height: 8rem;
    width: 8rem;
  }

  .card-icon-small {
    color: $palette-primary-color-three;
    font-size: 60px !important;
    font-size: 6rem !important;
    margin-bottom: .8rem;
    height: 6rem;
    width: 6rem;
  }

  .card-description {
    font-size: $type-scale-size-6-fallback;
    font-size: $type-scale-size-6;
    line-height: $type-scale-line-height-6-fallback;
    line-height: $type-scale-line-height-6;
    margin-bottom: 1.6rem;
    text-align: justify;
    text-justify: inter-word;
  }

  .like-count {
    @include flex-center-y;
    position: absolute;
    bottom: 2.4rem;
  }

  .transfer-btn-container {
    display: block;
    text-align: center;
    width: 100%;
  }

  .transfer-btn {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    @media (min-width: 961px) {
      display: inline-block;
      font-size: 20px;
      font-size: 2rem;
      height: 6rem;
      margin: 4rem 0;
      padding: 0 4rem;
    }
  }
}
</style>
