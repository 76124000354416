<template>
  <div class="DomainTransferVerification">
    <md-card v-if="isFormSending">
      <md-card-content>
        <div class="loader-container">
          <Loader />
          <h3 class="o-default-h3 u-text-center">{{ $t('domainTransferVerification.transferring') }} <span class="u-strong">{{ domainChecked }}</span> </h3>
        </div>
      </md-card-content>
    </md-card>
    <md-card v-else-if="transferCompleted">
      <md-card-content class="u-text-center">
        <img src="@/assets/images/checkmark.png"
              alt="Checkmark image"
              class="o-image-medium" />
        <p class="o-default-h3 u-text-center transfer-completed-title"><span class="u-strong u-green">{{ domainChecked }}</span> {{ $t('domainTransferVerification.domainTransferred') }}</p>
        <md-button class="md-dense md-raised md-primary navigate-btn"
                   @click="Helpers.goToRoute('my-account/user-domains', $router)">
          {{ $t('domainTransferVerification.viewYourDomains') }}
        </md-button>
      </md-card-content>
    </md-card>
    <md-card v-else-if="transferFailed">
      <md-card-content class="u-text-center">
        <i class="material-icons u-red error-icon">error</i>
        <p class="o-default-h3 u-text-center transfer-failed-title">{{ $t('domainTransferVerification.transferError') }}</p>
        <p class="o-default-body u-text-center transfer-failed-subtitle">{{ $t('domainTransferVerification.transferError2') }} {{ $t('contactEmailInreg') }}</p>
        <md-button class="md-dense md-raised md-primary navigate-btn"
                   @click="resetView">
          {{ $t('common.actions.retry') }}
        </md-button>
      </md-card-content>
    </md-card>
    <md-card v-else>
      <md-card-header class="card-title-header">
        <div class="md-title domain-transfer-verification-title">{{ $t('domainTransferVerification.transferToInregPre') }} <span class="u-strong u-green">{{ domainChecked }}</span> {{ $t('domainTransferVerification.transferToInregPost') }}</div>
      </md-card-header>
      <md-card-content>
        <AccordionDropdown dropdownKey="unlock" :warning="true">
          <template v-slot:dropdown-title>
            {{ $t('domainTransferVerification.unlock.title') }}
          </template>
          <template v-slot:dropdown-content>
            <p class="u-strong">
              {{ $t('domainTransferVerification.unlock.content1') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.unlock.content2') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.unlock.content3') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.unlock.content4') }}
            </p>
            <p>
             {{ $t('domainTransferVerification.unlock.content5') }}
            </p>
          </template>
        </AccordionDropdown>
        <AccordionDropdown dropdownKey="code" :success="!$v.domainTransferKey.$invalid">
          <template v-slot:dropdown-title>
            {{ $t('domainTransferVerification.transfer.title') }}
            <form novalidate @submit.prevent="validateTransferKey">
              <md-field class="transfer-field"
                        :class="getValidationClass('domainTransferKey')">
                <label for="domain-transfer-key">{{ $t('domainTransferVerification.transfer.transferKey') }}</label>
                <md-input name="domain-transfer-key"
                          autocomplete="domain-transfer-key"
                          v-model.trim="domainTransferKey"
                          v-autofocus
                          :disabled="isFormSending" />
                <span class="md-error" v-if="!$v.domainTransferKey.required">{{ $t('domainTransferVerification.error.domainTransferKeyRequired') }}</span>
                <span class="md-error" v-else-if="!$v.domainTransferKey.minLength">{{ $t('domainTransferVerification.error.domainTransferKeyMinLength') }}</span>
              </md-field>
              <md-button type="submit"
                         class="md-raised md-primary submit-btn"
                         :disabled="isFormSending">
                {{ $t('domainTransferVerification.transfer.transferToMyAccount') }}
              </md-button>
            </form>
          </template>
          <template v-slot:dropdown-content>
            <p class="u-strong">
              {{ $t('domainTransferVerification.transfer.content1') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.transfer.content2') }} <a href="https://rotld.ro" target="_blank">{{ $t('domainTransferVerification.transfer.content3') }}</a> {{ $t('domainTransferVerification.transfer.content4') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.transfer.content5') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.transfer.content6') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.transfer.content7') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.transfer.content8') }}
            </p>
            <p>
              {{ $t('domainTransferVerification.transfer.content9') }}
            </p>
          </template>
        </AccordionDropdown>
      </md-card-content>
    </md-card>
  </div> <!-- /.DomainTransferVerification -->
</template>

<script>
import Helpers from '@/common/helpers';
import Loader from '@/components/util/Loader';
import AccordionDropdown from '@/components/util/AccordionDropdown';
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength
} from 'vuelidate/lib/validators';
import {
  TRANSFER_DOMAIN,
} from '@/store/actions.type';

export default {
  name: 'DomainTransferVerification',
  mixins: [validationMixin],
  props: {
    domainChecked: {
      type: String,
      required: true
    }
  },
  components: {
    Loader,
    AccordionDropdown
  },
  data() {
    return {
      domainTransferKey: null,
      isFormSending: false,
      transferCompleted: false,
      transferFailed: false,
    }
  },
  created() {
    Helpers.scrollIntoView('md-focused');
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return { 'md-invalid': field.$invalid && field.$dirty }
      }
    },
    initiateTransfer() {
      const domainTransferPayload = {
        domain: this.domainChecked,
        key: this.domainTransferKey
      }

      this.isFormSending = true;
      this.$store.dispatch(TRANSFER_DOMAIN, domainTransferPayload)
        .then(() => {
          this.transferCompleted = true;
          Helpers.scrollIntoView('transfer-completed-title');
        })
        .catch(() => {
          this.transferFailed = true;
          Helpers.scrollIntoView('transfer-failed-title');
        })
        .finally(() => {
          this.isFormSending = false;
        });
    },
    validateTransferKey() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.initiateTransfer();
      }
    },
    resetView() {
      this.isFormSending = false;
      this.transferCompleted = false;
      this.transferFailed = false;
    }
  },
  validations: {
    domainTransferKey: {
      required,
      minLength: minLength(3)
    }
  },
};
</script>

<style lang="scss" scoped>
.DomainTransferVerification {

  .md-card-content {
    margin-top: 1.6rem;
  }

  .error-icon {
    font-size: 6rem;
  }

  .transfer-completed-title {
    margin: 0 auto 1.6rem;
  }

  .transfer-failed-title {
    margin: .8rem auto;
  }

  .transfer-failed-subtitle {
    margin: .8rem auto 2.4rem;
  }

  .card-title-header {
    margin-bottom: 0;
  }

  .domain-transfer-verification-title {
    font-family: $font-family-heading-regular;
    word-break: break-word;
  }

  .transfer-field {
    margin-bottom: 3.2rem;
    margin-top: 1.6rem;
    max-width: 32rem;
  }

  .submit-btn {
    margin: 0;
    min-width: 20rem;
  }
}
</style>
