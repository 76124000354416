<template>
  <section class="AccordionDropdown">
    <div class="accordion-dropdown-container" :class="{ open: expanded }">
      <div class="accordion-dropdown-title"
           :class="{
             warning: warning,
             success: success
           }"
           @click="toggleSlide">
        <slot name="dropdown-title"></slot>
        <md-icon class="keyboard-arrow-down">keyboard_arrow_down</md-icon>
      </div>
      <div class="accordion-dropdown-content" :class="dropdownKey">
        <slot name="dropdown-content"></slot>
      </div>
    </div>
  </section> <!-- /.AccordionDropdown -->
</template>

<script>
export default {
  name: 'AccordionDropdown',
  props: {
    dropdownKey: {
      type: String,
      required: true
    },
    warning: {
      type: Boolean,
      required: false,
      default: false
    },
    success: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    getHeight: function(el) {
      let el_style = window.getComputedStyle(el);
      let el_display = el_style.display;
      let el_position = el_style.position;
      let el_visibility = el_style.visibility;
      let el_max_height = el_style.maxHeight.replace('px', '').replace('%', '');

      let wanted_height = 0;

      // If it's not hidden we just return normal height
      if (el_display !== 'none' && el_max_height !== '0') {
        return el.offsetHeight;
      }

      // Making the el block so we can meassure its height but still be hidden
      el.style.position = 'absolute';
      el.style.visibility = 'hidden';
      el.style.display = 'block';

      wanted_height = el.offsetHeight + 48;

      // Reverting to the original values
      el.style.display = el_display;
      el.style.position = el_position;
      el.style.visibility = el_visibility;

      return wanted_height;
    },
    toggleSlide: function(event) {
      if (event.target.nodeName == 'INPUT') {
        return;
      } else {
        const el = document.querySelector(`.${this.dropdownKey}`);
        let el_max_height = 0;

        if (el.getAttribute('data-max-height')) {
          // We've already used this before, so everything is set
          if (el.style.maxHeight.replace('px', '').replace('%', '') === '0') {
            el.style.maxHeight = el.getAttribute('data-max-height');
            this.expanded = true;
          } else {
            el.style.maxHeight = '0';
            this.expanded = false;
          }
        } else {
          el_max_height = this.getHeight(el) + 'px';
          el.style['transition'] = 'max-height 0.4s ease-in-out';
          el.style.overflowY = 'hidden';
          el.style.maxHeight = '0';
          el.setAttribute('data-max-height', el_max_height);
          el.style.display = 'block';
          this.expanded = true;

          // We use setTimeout to modify maxHeight later than display
          setTimeout(function() {
            el.style.maxHeight = el_max_height;
          }, 10);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.AccordionDropdown {
  list-style-type: none;

  &:last-of-type {
    border-bottom: .1rem solid $palette-secondary-color-three;
  }

  .accordion-dropdown-container {
    border-top: .1rem solid $palette-secondary-color-three;
  }

  .accordion-dropdown-container .keyboard-arrow-down {
    position: absolute;
    top: 2rem;
    right: 1.2rem;
    left: auto;
    font-size: 3.2rem !important;
    width: 3.2rem;
    height: 3.2rem;
  }

  .accordion-dropdown-container.open .keyboard-arrow-down {
    transform: rotate(180deg);
  }

  .accordion-dropdown-title {
    @include disable-select;
    border-left: 1.2rem solid $palette-primary-color-three;
    cursor: pointer;
    font-family: $font-family-heading-light;
    font-size: 2.4rem;
    line-height: 1;
    padding: 2.4rem 1.2rem;
    position: relative;

    &.warning {
      border-left: 1.2rem solid $palette-primary-color-five;
    }

    &.success {
      border-left: 1.2rem solid $palette-primary-color-four;
    }
  }

  /**
   *  Accordion dropdown content
   */
  .accordion-dropdown-content {
    background: $palette-secondary-color-two;
    display: none;
    font-size: 1.6rem;
    list-style-type: none;

    p {
      display: block;
      padding: 1.6rem 1.6rem 0;
      @include tablet { padding: 1.6rem 1.6rem 0 3.2rem; }

      &:last-of-type {
        padding-bottom: 1.6rem;
      }
    }
  }
}
</style>
