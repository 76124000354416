<template>
  <div class="DomainTransferHowTo">
    <md-card class="card-steps">
      <md-card-header>
        <div class="md-title">{{ $t('domainTransferHowTo.title') }}</div>
      </md-card-header>
      <md-card-content class="transfer-steps-content">
        <div class="md-layout md-gutter">
          <section class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-100 md-xsmall-size-100 o-default-body transfer-step-column">
            <div class="transfer-step-label">
              <md-icon class="card-icon">fact_check</md-icon>
              <p class="o-default-h2">{{ $t('common.steps.step1') }}</p>
            </div>
            <md-divider></md-divider>
            <div class="transfer-step-content">
              <div class="md-title">
                {{ $t('domainTransferHowTo.step1.title') }}
              </div>
              <p class="o-default-body">
                {{ $t('domainTransferHowTo.step1.content1') }}
              </p>
              <p class="o-default-body">
                {{ $t('domainTransferHowTo.step1.content2') }}
              </p>
            </div>
          </section> <!-- /.transfer-step-column -->
          <section class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-100 md-xsmall-size-100 o-default-body transfer-step-column">
            <div class="transfer-step-label">
              <md-icon class="card-icon">security_update_good</md-icon>
              <p class="o-default-h2">{{ $t('common.steps.step2') }}</p>
            </div>
            <md-divider></md-divider>
            <div class="transfer-step-content">
              <div class="md-title">
                {{ $t('domainTransferHowTo.step2.title') }}
              </div>
              <p class="o-default-body">
                {{ $t('domainTransferHowTo.step2.content1') }}
              </p>
              <p class="o-default-body">
                {{ $t('domainTransferHowTo.step2.content2') }}
              </p>
            </div>
          </section> <!-- /.transfer-step-column -->
          <section class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-100 md-xsmall-size-100 o-default-body transfer-step-column">
            <div class="transfer-step-label">
              <md-icon class="card-icon">published_with_changes</md-icon>
              <p class="o-default-h2">{{ $t('common.steps.step3') }}</p>
            </div>
            <md-divider></md-divider>
            <div class="transfer-step-content">
              <div class="md-title">
                {{ $t('domainTransferHowTo.step3.title') }}
              </div>
              <p class="o-default-body">
                {{ $t('domainTransferHowTo.step3.content1') }}
              </p>
              <p class="o-default-body">
                {{ $t('domainTransferHowTo.step3.content2') }} "<span class="text-highlight">{{ $t('common.steps.step1') }}</span>".
              </p>
              <p class="o-default-body">
                {{ $t('domainTransferHowTo.step3.content3') }}
              </p>
              <p class="o-default-body">
                {{ $t('domainTransferHowTo.step3.content4') }}
              </p>
            </div>
          </section> <!-- /.transfer-step-column -->
        </div>
      </md-card-content> <!-- /.transfer-steps-content -->
    </md-card> <!-- /.card-steps -->
  </div> <!-- /.DomainTransferHowTo -->
</template>

<script>
export default {
  name: 'DomainTransferHowTo',
};
</script>

<style lang="scss" scoped>
.DomainTransferHowTo {

  .transfer-steps-content {
    margin-top: .8rem;
  }

  .transfer-step-column {
    margin-bottom: 2.4rem;
    @include tablet { margin-bottom: .8rem; }

    .transfer-step-label {
      display: flex;
    }

    .card-icon {
      color: $palette-primary-color-three;
      font-size: 6rem !important;
      height: 6rem;
      width: 6rem;
    }

    .o-default-h2 {
      color: $palette-primary-color-three;
      flex-grow: 1;
      font-family: $font-family-heading-light;
      margin: 1.6rem;
    }

    .transfer-step-content {
      margin: 1.6rem 0;

      p {
        font-size: $type-scale-size-6-fallback;
        font-size: $type-scale-size-6;
        line-height: $type-scale-line-height-5-fallback;
        line-height: $type-scale-line-height-5;
        margin: 1.6rem 0;
      }
    }
  }

  .text-highlight {
    color: $palette-primary-color-three;
    font-weight: 700;
  }
}
</style>
